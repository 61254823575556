@charset "UTF-8";

// Variables
@import "_variables";

/*!
 * Custom final overrides
 */
.nobr {
  white-space: nowrap !important;
  flex-wrap: nowrap !important;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.no-scroll {
  overflow: hidden;
}

.min-width {
  width: 1px;
}
.max-width {
  width: 100%;
}
.max-height {
  height: 100%;
}

.bordered {
  border: 1px solid $input-border;
}

.position-abs { position: absolute; }
.position-rel { position: relative; }
.position-b { bottom: 0; }
.position-r { right: 0; }
.form-input {
  width: 100%;
  border: 1px solid $input-border;
}

/*!
 * This is the fix for vuetify v-label margin which overrided by _reboot.scss
 */
.v-label {
  margin: 0;
}

/* Fix for the vuetify v-date-picker styles.  */
.v-date-picker-table .v-btn.v-btn--active {
  color: black;
}

/* Numeric input fields are right-aligned.  */
.numeric .v-text-field__slot input {
  text-align: right;
}

.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}
